<template>
  <div>
    <BaseModal
      size="md"
      :title="detailInfo.title.label"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <BaseLabel :columnInfo="detailInfo.message" />

        <BaseDateTime
          :labelVisible="false"
          :dateValue.sync="workactDate"
          :timeValue.sync="workactHhmmss"
          :dateColumnInfo="detailInfo.workact_date"
          :timeColumnInfo="detailInfo.workact_hhmmss"
        />
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- OKボタン -->
        <BaseButton
          variant="primary"
          :columnInfo="detailCtrlInfo.ok"
          @click="ok"
        />
        <!-- キャンセルボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="detailCtrlInfo.cancel"
          @click="cancel"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      workactDate: this.$moment(new Date()).format('YYYY-MM-DD'),
      workactHhmmss: this.$moment(new Date()).format('HH:mm:ss'),
    }
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.WORK_RECORD_LIST_SUB
      )('detail_ctrl_info')
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.WORK_RECORD_LIST_SUB
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
  },

  methods: {
    ok() {
      this.$bvModal.hide('workact-sub-modal')
      this.$emit('after-close-set', this.workactDate, this.workactHhmmss)
    },
  },
}
</script>
